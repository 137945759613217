import { Notification } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { FC } from 'react';

interface IAlertInfo {
	title: string;
	description: string;
	onClose: () => void;
	showNotification: boolean;
}
const AlertInfo: FC<IAlertInfo> = ({
	title,
	description,
	onClose,
	showNotification,
}) => {
	if (!showNotification) {
		return <></>;
	}
	return (
		<Notification
			pos={'fixed'}
			bottom={16}
			right={16}
			icon={<IconCheck size="1.1rem" />}
			color="teal"
			onClose={onClose}
			title={title}>
			{description}
		</Notification>
	);
};

export default AlertInfo;
