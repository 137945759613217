import React from 'react';
import { InputField } from '../../../types/Forms';
import { InputBuilderProps } from './Common/Types';
import { Stack, Textarea } from '@mantine/core';
import Actions from './Common/Actions';
import { getId } from '../FieldConfiguration/Common/Utils';
import { styles } from './Common/Styles';

export const getExampleTextarea = (): InputField => ({
	label: 'Bio',
	type: 'textarea',
	key: `textarea-${getId()}`,
	name: `bio-${getId()}`,
	description: 'Share your BIO here',
	placeholder: 'Type your bio here...',
	validation: {
		required: false,
		maxLength: 500,
	},
});

const TextAreaBuilder = ({
	field,
	isEditing,
	onEditing,
	onDeleting,
	isSignup,
	onChangeText,
}: InputBuilderProps) => {
	const { value, label, description, placeholder, validation } = field;

	return (
		<Stack style={isEditing ? styles.containerEditing : styles.container}>
			<Textarea
				label={label}
				description={description}
				placeholder={placeholder}
				maxLength={validation.maxLength}
				minLength={validation.minLength}
				required={validation.required}
				value={value}
				onChange={(event) => {
					const value = event.currentTarget.value;
					onChangeText?.({ ...field, value });
				}}
				disabled={!isSignup}
			/>

			{!isSignup ? (
				<Actions onEditing={onEditing} onDeleting={onDeleting} />
			) : undefined}
		</Stack>
	);
};

export default TextAreaBuilder;
