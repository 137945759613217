/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import {isError} from 'lodash';

const __DEV__ = process.env.NODE_ENV === 'development';
// Helpers
export const enum LOG_LEVEL {
  DEBUG,
  INFO,
  WARN,
  ERROR,
}

function log(level: LOG_LEVEL, message: string, print = true) {
  let formattedMessage = message;
  if (typeof message !== 'string') {
    formattedMessage = JSON.stringify(message);
  }
  formattedMessage = formattedMessage;

  if (!__DEV__) {
    // crashlytics().log(formattedMessage);
  }

  const date = new Date().toLocaleString();
  if (print) {
    switch (level) {
      case LOG_LEVEL.DEBUG:
        console.log(date, formattedMessage);
        break;
      case LOG_LEVEL.INFO:
        console.info(date, formattedMessage);
        break;
      case LOG_LEVEL.WARN:
        console.warn(date, formattedMessage);
        break;
      case LOG_LEVEL.ERROR:
        console.error(date, formattedMessage);
        break;
    }
  }
}

/**
 * Write debug logs to the console with decoration
 * @param {string} message
 * @param {boolean} [enabled=true]?
 * strings are logged without decoration. Objects are colorized
 * and pretty printed. If multiple arguments are passed then
 * they will be grouped with box drawings to easily see relation
 * Logs will no output for release builds
 */
export function logDebug(message: string, enabled = true) {
  log(LOG_LEVEL.DEBUG, `${new Date()}::${message}`, __DEV__ && enabled);
}

/**
 * Write info logs to the console with decoration
 * @param {string} message
 * strings are logged without decoration. Objects are colorized
 * and pretty printed. If multiple arguments are passed then
 * they will be grouped with box drawings to easily see relation.
 * Logs will be attached to crash reports.
 */
export function logInfo(message: string) {
  log(LOG_LEVEL.INFO, `${new Date()}::${message}`);
}

/**
 * Write warn logs to the console with decoration
 * @param {string} message Plain
 * strings are logged without decoration. Objects are colorized
 * and pretty printed. If multiple arguments are passed then
 * they will be grouped with box drawings to easily see relation.
 * Logs will be attached to crash reports.
 */
export function logWarn(message: string) {
  log(LOG_LEVEL.WARN, message);
}

/**
 * Write error logs to the console with decoration
 * @param {string} name
 * @param {any} error
 * Accepts any number of arguments to log. Plain
 * strings are logged without decoration. Objects are colorized
 * and pretty printed. If multiple arguments are passed then
 * they will be grouped with box drawings to easily see relation
 * Error logs will report non-fatal crashes with Crashlytics.
 */
export function logError(name: string, error: any) {
  if (!__DEV__) {
    log(LOG_LEVEL.ERROR, `${name} - ${error}`);
    const err = isError(error) ? error : new Error(error);
    err.name = name;
    // crashlytics().recordError(err);
  } else {
    log(LOG_LEVEL.WARN, `${name} - ${error}`);
  }
}
