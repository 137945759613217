import { useState } from 'react';
import { createForm, getFormById, updateForm } from '../../services/network/Formapi';
import { InputField } from '../../types/Forms';
import { isEmpty, isString } from '../../utils';
import useSession from '../useSession';
import { logError } from '../../services/Log';

const useCreateCampaign = () => {
  const { user: { id } } = useSession();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [form, setForm] = useState({
    title: '',
    description: '',
    image: '',
    sections: [],
  });
  const [fieldEditing, setFieldEditing] = useState<InputField | null>(null);

  const fetchForm = async (formId: string) => {
    try {
      setLoading(true);
      const remote = await getFormById({ formId });

      if (remote !== null) {
        const schema = isString(remote.formSchema)
          ? JSON.parse(remote.formSchema)
          : remote.formSchema;
        setForm(schema);
      }
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const onHandleCreate = async () => {
    let response = null;

    try {
      setError('');
      setLoading(true);
      const formSchema = { ...form };
      response = await createForm({ userId: `${id}`, formSchema });
    } catch (exception) {
      logError('Create Form', JSON.stringify(exception));
    } finally {
      setLoading(false);
    }

    return response;
  };

  const onHandleUpdate = async (formId: string) => {
    let response = null;

    try {
      setError('');
      setLoading(true);
      const formSchema = { ...form };
      response = await updateForm({ formId, formSchema });
    } catch (exception) {
      logError('Create Form', JSON.stringify(exception));
    } finally {
      setLoading(false);
    }

    return response;
  };

  const checkFormErrors = () => {
    if (isEmpty(form.title) || !isString(form.title)) {
      return 'Title is required';
    }

    return '';
  };

  return {
    loading,
    fieldEditing,
    form,
    error,
    fetchForm,
    setFieldEditing,
    setForm,
    onHandleCreate,
    onHandleUpdate,
    checkFormErrors,
  };
};

export default useCreateCampaign;
