import { Stack, TextInput } from '@mantine/core';
import { InputField } from '../../../types/Forms';
import Actions from './Common/Actions';
import { InputBuilderProps } from './Common/Types';
import { getId } from '../FieldConfiguration/Common/Utils';
import { styles } from './Common/Styles';

export const getExampleInput = (): InputField => ({
	label: 'Input Label',
	type: 'text',
	key: `text-${getId()}`,
	name: `text-${getId()}`,
	description: 'Enter your input value',
	placeholder: 'Input',
	validation: {
		required: true,
		minLength: 2,
		maxLength: 50,
	},
});

const InputTextBuilder = ({
	field,
	isEditing,
	onEditing,
	onDeleting,
	isSignup,
	onChangeText,
}: InputBuilderProps) => {
	const { value, label, description, placeholder, validation } = field;

	return (
		<Stack style={isEditing ? styles.containerEditing : styles.container}>
			<TextInput
				type="text"
				label={label}
				description={description}
				placeholder={placeholder}
				required={validation.required}
				minLength={validation.minLength}
				maxLength={validation.maxLength}
				pattern={validation.pattern?.length ? validation.pattern : undefined}
				value={value}
				onChange={(event) => {
					const value = event.currentTarget.value;
					onChangeText?.({ ...field, value });
				}}
				disabled={!isSignup}
			/>

			{!isSignup ? (
				<Actions onEditing={onEditing} onDeleting={onDeleting} />
			) : undefined}
		</Stack>
	);
};

export default InputTextBuilder;
