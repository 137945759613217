import InputTextBuilder from './FieldRenderer/InputTextBuilder';
import EmailBuilder from './FieldRenderer/EmailBuilder';
import { InputBuilderProps } from './FieldRenderer/Common/Types';
import PasswordBuilder from './FieldRenderer/PasswordBuilder';
import DateBuilder from './FieldRenderer/DateBuilder';
import CheckboxBuilder from './FieldRenderer/CheckboxBuilder';
import RadioBuilder from './FieldRenderer/RadioBuilder';
import SelectBuilder from './FieldRenderer/SelectBuilder';
import TextAreaBuilder from './FieldRenderer/TextareaBuilder';
import { InputField, InputType } from '../../types/Forms';
import HiddenTextBuilder from './FieldRenderer/HiddenBuilder';

interface FormRendererProps {
	type: InputType;
	field: InputBuilderProps | any;
	onEditing?: (field: InputField) => void;
	onDeleting?: (field: InputField) => void;
	onChangeText: (field: InputField) => void;
	isEditing: boolean;
	isSignup?: boolean;
}

const FormRenderer = ({
	type,
	field,
	onEditing,
	onDeleting,
	isEditing,
	...others
}: FormRendererProps) => {
	switch (type) {
		case 'text':
			return (
				<InputTextBuilder
					key={field.key}
					{...others}
					isEditing={isEditing}
					field={field}
					onEditing={() => onEditing(field)}
					onDeleting={() => onDeleting(field)}
				/>
			);
		case 'email':
			return (
				<EmailBuilder
					key={field.key}
					{...others}
					isEditing={isEditing}
					field={field}
					onEditing={() => onEditing(field)}
					onDeleting={() => onDeleting(field)}
				/>
			);
		case 'password':
			return (
				<PasswordBuilder
					key={field.key}
					{...others}
					isEditing={isEditing}
					field={field}
					onEditing={() => onEditing(field)}
					onDeleting={() => onDeleting(field)}
				/>
			);
		case 'date':
			return (
				<DateBuilder
					key={field.key}
					{...others}
					isEditing={isEditing}
					field={field}
					onEditing={() => onEditing(field)}
					onDeleting={() => onDeleting(field)}
				/>
			);
		case 'checkbox':
			return (
				<CheckboxBuilder
					key={field.key}
					{...others}
					isEditing={isEditing}
					field={field}
					onEditing={() => onEditing(field)}
					onDeleting={() => onDeleting(field)}
				/>
			);
		case 'radio':
			return (
				<RadioBuilder
					key={field.key}
					{...others}
					isEditing={isEditing}
					field={field}
					onEditing={() => onEditing(field)}
					onDeleting={() => onDeleting(field)}
				/>
			);
		case 'select':
			return (
				<SelectBuilder
					key={field.key}
					{...others}
					isEditing={isEditing}
					field={field}
					onEditing={() => onEditing(field)}
					onDeleting={() => onDeleting(field)}
				/>
			);
		case 'textarea':
			return (
				<TextAreaBuilder
					key={field.key}
					{...others}
					isEditing={isEditing}
					field={field}
					onEditing={() => onEditing(field)}
					onDeleting={() => onDeleting(field)}
				/>
			);
		case 'hidden':
			return (
				<HiddenTextBuilder
					key={field.key}
					{...others}
					isEditing={isEditing}
					field={field}
					onEditing={() => onEditing(field)}
					onDeleting={() => onDeleting(field)}
					onChangeText={(updatedField) => others.onChangeText(updatedField)}
				/>
			);
		default:
			return null;
	}
};

export default FormRenderer;
