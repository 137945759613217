/**
 * Configuration
 * [env]: development | qa | production
 * To change environment run the command `yarn config-[env]`
 */
import env from '../../env';
import development from './dev.json';
import qa from './qa.json';
import prod from './prod.json';
import { IConfig } from '../../types/common';

// Default configuration
const configurationSets = {
  development,
  qa,
  prod,
} as IConfig;

export default configurationSets[env];