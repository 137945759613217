/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import NavigationContainer from '../components/NavigationContainer';
import {
	Anchor,
	Box,
	Button,
	Group,
	Loader,
	Stack,
	Table,
	Tabs,
	Text,
	Title,
} from '@mantine/core';
import useEntries from '../hooks/screens/useEntries';
import { formatDate, isString } from '../utils';
import { InputField } from '../types/Forms';

const Entries: React.FC = () => {
	const {
		loading,
		forms,
		entries,
		selectedForm,
		handleChangeTab,
		handleSaveDataToJson,
		handleSaveDataToCsv,
	} = useEntries();

	return (
		<NavigationContainer>
			<Group mb={24}>
				<Title order={1} color="white">
					Entries
				</Title>
			</Group>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					marginTop: '16px',
				}}>
				{loading ? (
					<Loader color="orange" />
				) : (
					<Tabs
						value={selectedForm}
						onTabChange={handleChangeTab}
						sx={{
							width: '100%',
						}}>
						<Tabs.List
							grow
							sx={{
								backgroundColor: '#212529',
							}}>
							{forms.map((campaign, key) => {
								const formSchema = isString(campaign.formSchema)
									? JSON.parse(campaign.formSchema as unknown as string)
									: campaign.formSchema;

								return (
									<Tabs.Tab
										value={key.toString()}
										color="orange"
										sx={{
											padding: '20px',
										}}>
										{formSchema.title}
									</Tabs.Tab>
								);
							})}
						</Tabs.List>

						{forms?.map((campaign, key) => {
							const formSchema = isString(campaign.formSchema)
								? JSON.parse(campaign.formSchema as unknown as string)
								: campaign.formSchema;

							const activeColumns = formSchema?.sections || [];

							return (
								<Tabs.Panel
									key={campaign._id}
									value={key.toString()}
									sx={{
										width: '100%',
										backgroundColor: '#212529',
										marginTop: '16px',
									}}>
									{activeColumns.length && entries.length ? (
										<Box w="100%">
											<Group
												position="right"
												bg="#333A40"
												p={8}>
												<Button
													color="orange"
													variant="light"
													onClick={handleSaveDataToJson}>
													<Anchor color="orange">Get JSON</Anchor>
												</Button>
												<Button
													color="orange"
													variant="light"
													onClick={handleSaveDataToCsv}>
													<Anchor color="orange">Get CSV</Anchor>
												</Button>
											</Group>
											<Table
												withBorder
												striped
												highlightOnHover
												withColumnBorders
												verticalSpacing="lg">
												<thead>
													<tr>
														{activeColumns.map((column) => {
															return (
																<th key={column.key}>
																	<Text size="lg">{column.label}</Text>
																</th>
															);
														})}
													</tr>
												</thead>
												<tbody>
													{entries?.map((entry) => {
														const data = entry.formData;

														return (
															<tr key={`${entry._id}-${campaign._id}`}>
																{activeColumns.map((column: InputField) => {
																	const type = column.type;
																	let value = data[column.name];

																	switch (type) {
																		case 'checkbox':
																			value = value?.join(', ');
																			break;
																		case 'date':
																			value = formatDate(value);
																			break;

																		default:
																			value = data[column.name];
																			break;
																	}

																	return (
																		<td
																			key={`${entry._id}-${campaign._id}-${column.key}`}>
																			<Text>{value}</Text>
																		</td>
																	);
																})}
															</tr>
														);
													})}
												</tbody>
											</Table>
										</Box>
									) : (
										<Box
											sx={{
												width: '100%',
												display: 'flex',
												justifyContent: 'center',
												padding: '16px 0px',
											}}>
											<Stack align="center">
												{!activeColumns.length ? (
													<Text color="white">
														It appears that this campaign does not contain any
														active or compatible columns
													</Text>
												) : undefined}

												{!entries.length ? (
													<Text color="white">
														It seems that this campaign has not yet registered
														any entity.
													</Text>
												) : undefined}
											</Stack>
										</Box>
									)}
								</Tabs.Panel>
							);
						})}
					</Tabs>
				)}
			</Box>
		</NavigationContainer>
	);
};

export default Entries;
