/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { registerUser } from '../../services/auth';
import { useDisclosure, useToggle } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { NotificationType } from '../../types/placeholderUser.type';
import { validateEmail, validPassword } from '../../utils';
import StatesJSON from '../../assets/data/states.json';

const NOTIFICATION_MESSAGES = {
	SUCCESS: {
		title: 'Successful registration!',
		description: 'Check your email inbox.',
	},
};

const useRegisterUser = () => {
	const [isLoading, { open: onStart, close: onFinish }] = useDisclosure();
	const [
		showNotification,
		{ open: onShowNotification, close: onCloseNotification },
	] = useDisclosure();
	const [notificationMessage, setNotificationMessage] = useState<
		NotificationType | undefined
	>();
	const navigate = useNavigate();
	const [wizard, toggleWizard] = useToggle(['email', 'info']);
	const [statesList, setStatesList] = useState([]);
	const [emailData, setEmailData] = useState({});

	const onShowSuccessNotification = () => {
		setNotificationMessage(NOTIFICATION_MESSAGES.SUCCESS);
		onShowNotification();
		setTimeout(() => {
			onCloseNotification();
			navigate('/login');
		}, 5000);
	};

	useEffect(() => {
		const tempList = StatesJSON.states.map((item: any) => {
			return {
				value: item.name,
				label: item.name,
			}
		});
		setStatesList(tempList);
	}, []);

	useEffect(() => {
		console.log('statesList===>', statesList);
	}, [statesList]);

	const handleEmailSubmit = (values: any) => {
		console.log('values===>', values);
		setEmailData({
			...values
		});
		toggleWizard();
	}

	const handleInfoSubmit = (values: any) => {
		const data = {
			...values,
			...emailData
		};
		console.log('values===>', data);
		
		handleSubmit(data);
	}

	const handleSubmit = async (values: any) => {
		try {
			onStart();
			await registerUser(values);
			onShowSuccessNotification();
		} catch (error) {
			console.log(error);
		} finally {
			onFinish();
		}
	};

	const formEmail = useForm({
		initialValues: {
			email: '',
			username: '',
			password: '',
			confirmPassword: '',
		},

		validate: {
			email: (val) => (validateEmail(val) ? null : 'Invalid email'),
			password: (val) =>
				validPassword(val)
					? null
					: 'Password should include at least 8 characters',
			confirmPassword: (val, values) =>
				validPassword(val)
					? (val !== values.password ? 'Passwords did not match' : null)
					: 'Password should include at least 8 characters',
		},
	});

	const formInfo = useForm({
		initialValues: {
			name: '',
			state: '',
			city: '',
			zip: '',
			address: '',
			phoneNumber: '',
			terms: false,
		},
	});

	return {
		statesList,
		wizard,
		toggleWizard,
		formEmail,
		formInfo,
		handleEmailSubmit,
		handleInfoSubmit,
		handleSubmit,
		isLoading,
		showNotification,
		notificationMessage,
		onCloseNotification,
	};
};

export default useRegisterUser;
