import { useState } from 'react';
import {
	Group,
	Box,
	Collapse,
	ThemeIcon,
	Text,
	UnstyledButton,
} from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { Route } from '../../types/routes.type';
import useStyles from './useStyles';

interface LinksGroupProps extends Route {
	currentPath?: string;
}

export function LinksGroup({
	icon: Icon,
	label,
	initiallyOpened,
	links,
	currentPath,
	link,
}: LinksGroupProps) {
	const { classes, theme } = useStyles();
	const [opened, setOpened] = useState(initiallyOpened || false);
	const hasLinks = Array.isArray(links);
	const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft;
	const SELECTED_COLOR = theme.colors.dark[4];
	const SELECTED_COLOR_TEXT = theme.colors.orange[4];

	const items = (hasLinks ? links : []).map((item) => (
		<Text
			component="a"
			className={classes.link}
			key={item.label}
			href={item.link}
			bg={currentPath === item.link && SELECTED_COLOR}>
			{item.label}
		</Text>
	));

	return (
		<>
			<UnstyledButton
				component='a'
				href={link}
				onClick={() => {
					setOpened((o) => !o);
				}}
				className={classes.control}
				bg={currentPath === link && SELECTED_COLOR}>
				<Group position="apart" spacing={0}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<ThemeIcon variant="light" size={30}>
							<Icon size="1.1rem" color="orange" />
						</ThemeIcon>
						<Box ml="md">
							<Text color={currentPath === link && SELECTED_COLOR_TEXT}>{label}</Text>
						</Box>
					</Box>
					{hasLinks && (
						<ChevronIcon
							className={classes.chevron}
							size="1rem"
							stroke={1.5}
							style={{
								transform: opened
									? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)`
									: 'none',
							}}
						/>
					)}
				</Group>
			</UnstyledButton>
			{hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
		</>
	);
}
