import {
	Table,
	Anchor,
	Group,
	Button,
	Image,
	Text,
	Stack,
} from '@mantine/core';
import {
	IconEdit,
	IconTrash,
	IconEye,
	IconDownload,
} from '@tabler/icons-react';
import { FormSchema, RemoteForm } from '../../types/Forms';
import { isString } from '../../utils';
import QRCode from 'react-qr-code';
import { useRef } from 'react';
import html2canvas from 'html2canvas';

interface FormListingProps {
	data: RemoteForm[];
	onDelete: (id: string) => void;
	loading: boolean;
}

interface FormRow {
	element: RemoteForm;
	loading: boolean;
	onDelete: (id: string) => void;
}

const FormListing = ({ data, loading, onDelete }: FormListingProps) => (
	<Table
		withBorder
		striped
		highlightOnHover
		withColumnBorders
		verticalSpacing="lg">
		<thead>
			<tr>
				<th>
					<Text size="lg" fw={500}>
						Title
					</Text>
				</th>
				<th>
					<Text size="lg" fw={500}>
						Description
					</Text>
				</th>
				<th>
					<Text size="lg" fw={500}>
						Brand Image
					</Text>
				</th>
				<th>
					<Text size="lg" fw={500} style={{ textAlign: 'center' }}>
						QR Code
					</Text>
				</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			{data.map((element) => (
				<FormRow
					key={element._id}
					element={element}
					onDelete={onDelete}
					loading={loading}
				/>
			))}
		</tbody>
	</Table>
);

const FormRow = ({ element, loading, onDelete }: FormRow) => {
	const formSchema = (
		isString(element.formSchema)
			? JSON.parse(element.formSchema as unknown as string)
			: element.formSchema
	) as FormSchema;

	const title = formSchema.title ?? 'Not title';
	const currentHost = `${window.location.protocol}//${window.location.hostname}`;

	const qrCodeRef = useRef<any>(null);

	const handleDownload = async () => {
		const content = qrCodeRef.current;
		const qrCodeCanvas = await html2canvas(content, {
			useCORS: true,
		});

		if (qrCodeCanvas) {
			const downloadLink = document.createElement('a');
			downloadLink.href = qrCodeCanvas.toDataURL();
			downloadLink.download = `CAMPAIGN_${formSchema.title}_QR_CODE.png`;
			downloadLink.click();
		}
	};

	return (
		<tr key={element._id}>
			<td>{title}</td>
			<td>{formSchema.description ?? 'No Description'}</td>
			<td>
				{formSchema.image ? (
					<Image
						w={100}
						src={formSchema.image}
						style={{ width: '100' }}
						alt={title}
					/>
				) : null}
			</td>
			<td>
				<Stack display="flex" justify="center" align="center">
					<div
						ref={qrCodeRef}
						style={{
							width: '250px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							padding: '15px',
							borderRadius: '8px',
							backgroundColor: 'white',
						}}>
						{formSchema.image ? (
							<Image
								src={formSchema.image}
								w="auto"
								height={100}
								fit="contain"
								style={{ marginBottom: '8px' }}
								alt={title}
							/>
						) : null}

						{formSchema?.title ? (
							<Text
								size="lg"
								fw={700}
								style={{ textAlign: 'center', color: 'black' }}>
								{formSchema?.title}
							</Text>
						) : undefined}

						{formSchema?.description ? (
							<Text
								size="md"
								style={{
									textAlign: 'center',
									marginBottom: '16px',
									color: 'black',
								}}>
								{formSchema?.description}
							</Text>
						) : undefined}

						<div
							style={{
								padding: '5px',
								border: '5px solid black',
								backgroundColor: 'white',
								borderRadius: 16,
							}}>
							<div
								style={{
									padding: '5px',
									border: '5px solid black',
									backgroundColor: 'black',
									borderRadius: 16,
								}}>
								<div
									style={{
										backgroundColor: 'white',
										padding: '10px',
										borderRadius: 16,
									}}>
									<QRCode
										size={256}
										value={`${currentHost}/sign-up-campaign/${
											element?._id || ''
										}`}
										viewBox={`0 0 256 256`}
										style={{
											height: '150px',
											width: '150px',
										}}
									/>
								</div>

								<Text
									size="md"
									fw={700}
									style={{
										textAlign: 'center',
										color: 'white',
										marginTop: '15px',
									}}>
									SCAN ME
								</Text>
							</div>
						</div>
					</div>

					<Stack sx={{ display: 'flex', flexDirection: 'row' }} align="center">
						<Button variant="outline" color="orange">
							<Anchor
								href={`${currentHost}/sign-up-campaign/${element?._id || ''}`}
								target="_blank">
								<IconEye color="orange" stroke={1.5} />
							</Anchor>
						</Button>

						<Button variant="outline" color="orange" onClick={handleDownload}>
							<IconDownload color="orange" stroke={1.5} />
						</Button>
					</Stack>
				</Stack>
			</td>
			<td>
				{loading ? null : (
					<Group style={{ justifyContent: 'center' }}>
						<Button variant="outline" color="orange">
							<Anchor href={`/formbuilder?id=${element._id}`}>
								<IconEdit color="orange" stroke={1.5} />
							</Anchor>
						</Button>
						<Button
							variant="outline"
							color="red"
							onClick={() => onDelete(element._id)}>
							<IconTrash color="red" stroke={1.5} />
						</Button>
					</Group>
				)}
			</td>
		</tr>
	);
};

export default FormListing;
