import { Group } from "@mantine/core";
import AddFieldButton from "./AddFieldButton";
import { InputType } from "../../types/Forms";

const InputPicker = ({ onAddInput }: { onAddInput: (input: InputType) => void; }) => (
  <Group py={16}>
    <AddFieldButton
      icon="IconEye"
      title="Metadata"
      onPress={() => onAddInput('hidden')}
    />
    <AddFieldButton
      icon="IconTextSize"
      title="Text"
      onPress={() => onAddInput('text')}
    />
    <AddFieldButton
      icon="IconMail"
      title="Email"
      onPress={() => onAddInput('email')}
    />
    <AddFieldButton
      icon="IconPassword"
      title="Password"
      onPress={() => onAddInput('password')}
    />
    <AddFieldButton
      icon="IconCalendar"
      title="Date"
      onPress={() => onAddInput('date')}
    />
    <AddFieldButton
      icon="IconSelect"
      title="Select"
      onPress={() => onAddInput('select')}
    />
    <AddFieldButton
      icon="IconCirclePlus"
      title="Radio"
      onPress={() => onAddInput('radio')}
    />
    <AddFieldButton
      icon="IconCheckbox"
      title="Checkbox"
      onPress={() => onAddInput('checkbox')}
    />
    <AddFieldButton
      icon="IconTextPlus"
      title="TextArea"
      onPress={() => onAddInput('textarea')}
    />
  </Group>
);

export default InputPicker;