import { User, RegisterData } from '../types/placeholderUser.type';

export type LoginProps = {
	email: string;
	password: string;
};
export const postLogin = async ({ email, password }: LoginProps) => {
	return new Promise<User>((resolve) => {
		setTimeout(() => {
			resolve({
				name: 'Alessia Abernathy',
				phone: '2048451862 x255',
				username: 'Dr.ArturoStreich',
				website: 'https://shockoe.com',
				email: email,
				image:
					'https://2208386.fs1.hubspotusercontent-na1.net/hub/2208386/hubfs/shockoe%20logo.jpg?width=108&height=108',
				address: {
					city: 'Boston',
					geo: {
						lat: '12.123123',
						lng: '12.123213',
					},
					street: 'Lamar Pines',
					suite: password,
					zipcode: 'Asdsad',
				},
				company: {
					bs: 'RANDOM',
					catchPhrase: 'vero',
					name: 'Lueilwitzborough',
				},
				id: 1002,
			});
		}, 3000);
	});
};

export const postRestorePassword = async (email: string) => {
	return new Promise<boolean>((resolve) => {
		setTimeout(() => {
			resolve(!!email);
		}, 3000);
	});
};

export const registerUser = async (data: RegisterData) => {
	return new Promise<boolean>((resolve) => {
		setTimeout(() => {
			resolve(!!data);
		}, 3000);
	});
};
