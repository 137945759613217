import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { FormSchema } from '../../types/Forms';
import { createEntry, getFormById } from '../../services/network/Formapi';
import { isEmpty, isString } from '../../utils';
import toast from 'react-hot-toast';

const useSignupCampaign = () => {
	const { formId } = useParams();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>();
	const [successfulJoin, setSuccessfullJoin] = useState(false);

	const [campaginInfo, setCampaignInfo] = useState<FormSchema>();

	const form = useForm({
		initialValues: {} as { [x: string]: any },
	});

	useEffect(() => {
		if (formId) {
			fetchCampaign(formId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formId]);

	const fetchCampaign = async (id: string) => {
		onStartLoading();

		try {
			const remote = await getFormById({ formId: id });

			if (remote !== null) {
				const schema = isString(remote.formSchema)
					? JSON.parse(remote.formSchema)
					: remote.formSchema;

				setCampaignInfo(schema);
			} else {
				throw Error(
					'An error occurred while searching for the campaign, check with your supplier that the campaign is successful or active.',
				);
			}
		} catch (exception: any) {
			console.error(exception);
			setError(exception?.message);
		} finally {
			setLoading(false);
		}
	};

	const onSignupCampaign = async (data: {
		formId: string;
		formData: {
			[x: string]: any;
		};
	}) => {
		onStartLoading();
		console.log('[onSignupCampaign] data', data);

		try {
			const remote = await createEntry(data);

			if (remote !== null) {
				setSuccessfullJoin(true);
			} else {
				throw Error(
					'There was an error joining the campaign, check with your provider that the campaign is correct or active and try again later.',
				);
			}
		} catch (exception: any) {
			console.error(exception);
			setError(exception?.message);
		} finally {
			setLoading(false);
		}
	};

	const onStartLoading = () => {
		setLoading(true);
		setError(undefined);
		setSuccessfullJoin(false);
	};

	const handleSubmit = (values: { [x: string]: any }) => {
		const formattedHiddenValues = {};

		campaginInfo.sections
			.filter((sec) => sec.type === 'hidden')
			.forEach((sec) => {
				formattedHiddenValues[sec.name] = sec.value;
			});

		const formData = {
			...values,
			...formattedHiddenValues,
		};

		const requiredValues = campaginInfo.sections.filter((s) => {
			if (s.validation.required && s.type !== 'hidden') {
				const value = formData[s.name as unknown as keyof typeof formData];
				const isInvalidValue = isEmpty(value);
				return s.validation.required && isInvalidValue;
			} else {
				return false;
			}
		});

		if (requiredValues.length) {
			return toast.error(
				requiredValues[0]?.description ||
					`Please enter a valid value ${requiredValues[0].label}`,
			);
		}

		onSignupCampaign({
			formId,
			formData: {
				...values,
				...formattedHiddenValues,
			},
		});
	};

	return {
		form,
		loading,
		error,
		successfulJoin,
		campaginInfo,
		handleSubmit,
	};
};

export default useSignupCampaign;
