import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	footer: {
		backgroundColor:
			theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
		color: theme.colorScheme === 'dark' ? 'white' : theme.colors.dark[6],
	},
}));

export default useStyles;
