import React from 'react';
import { ActionIcon, Checkbox, Divider, Group, ScrollArea, Stack, Text, TextInput, Title } from '@mantine/core';
import OptionsBuilder from './Common/OptionsBuilder';
import { IconPlus } from '@tabler/icons-react';
import { getId } from './Common/Utils';
import { InputField, InputOption, InputType } from '../../../types/Forms';

export interface FieldConfigurationProps {
  type: InputType;
  field: InputField;
  onChangeConfiguration: (field: InputField) => void;
};

const FieldConfiguration = ({ type, field, onChangeConfiguration }: FieldConfigurationProps) => {

  const onChange = (event) => {
    const { id, value } = event.currentTarget;
    const _field = { ...field };

    switch (id) {
      case 'name':
        _field.name = value;
        break;
      case 'placeholder':
        _field.placeholder = value;
        break;
      case 'label':
        _field.label = value;
        break;
      case 'description':
        _field.description = value;
        break;
      case 'required':
        _field.validation.required = !_field.validation.required;
        break;
      case 'minlength':
        _field.validation.minLength = value?.length === 0 ? undefined : parseInt(value);
        break;
      case 'maxlength':
        _field.validation.maxLength = value?.length === 0 ? undefined : parseInt(value);
        break;
      case 'pattern':
        _field.validation.pattern = value;
        break;
    }

    onChangeConfiguration(_field);
  };

  const onAddOption = (f: InputField) => {
    const id = getId();
    const option = {
      key: `option-${id}`,
      label: `Option ${id}`,
      value: `opt${id}`,
    };

    f.options.push(option);
    onChangeConfiguration({ ...f });
  };

  const onDeleteOption = (f: InputField, option: InputOption) => {
    const index = f.options.findIndex(o => o.value === option.value && o.label === option.label);

    if (index !== -1) {
      f.options.splice(index, 1);
      onChangeConfiguration({ ...f });
    }
  };

  const onUpdateOption = (f: InputField, option: InputOption, change: { key: string; value: string; }) => {
    const index = f.options.findIndex(o => o.value === option.value && o.label === option.label);

    if (index !== -1) {
      f.options[index][change.key] = change.value;
      onChangeConfiguration({ ...f });
    }
  };

  const renderInputTypeConfigurationComponent = (type: InputType) => {
    switch (type) {
      case 'text':
      case 'email':
      case 'password':
      case 'textarea':
        return <TextConfig field={field} onChange={onChange} />;
      case 'checkbox':
      case 'radio':
      case 'select':
        return <OptionsConfig field={field} onDelete={onDeleteOption} onChange={onUpdateOption} onAdd={onAddOption} />;
      case 'date':
        return null;
    }
  }

  return (
    <Stack bg="dark.7" p={16} style={{ borderRadius: 8 }}>
      <Title order={4} mb={16}>Input Configuration</Title>
      <Divider />
      <ScrollArea h={600}>
        <CommonConfig isHidden={field.type === 'hidden'} field={field} onChange={onChange} />
        {renderInputTypeConfigurationComponent(type)}
      </ScrollArea>
    </Stack>
  );
};

const CommonConfig = ({ field, onChange, isHidden }) => (
  <>
    <TextInput label="Name" placeholder="Name" value={field.name} id="name" description="The unique name of this input" required onChange={onChange} />
    {isHidden ? null : (
      <>
        <Checkbox label="Required" checked={field.validation.required} id="required" onChange={onChange} my={16} />
        <TextInput label="Title" placeholder="Title" value={field.label ?? ""} id="label" onChange={onChange} />
        <TextInput label="Description" placeholder="Description" id="description" value={field.description ?? ""} onChange={onChange} />
      </>
    )}
  </>
);

const TextConfig = ({ field, onChange }) => (
  <>
    <TextInput label="Placeholder" placeholder="Placeholder" id="placeholder" value={field.placeholder ?? ""} onChange={onChange} />
    <TextInput label="Min Length" value={field.validation.minLength ?? ""} id="minlength" inputMode="numeric" onChange={onChange} />
    <TextInput label="Max Length" value={field.validation.maxLength ?? ""} id="maxlength" inputMode='numeric' onChange={onChange} />
    <TextInput label="Pattern" value={field.validation.pattern ?? ""} id="pattern" onChange={onChange} />
  </>
);

interface OptionsConfigProps {
  field: InputField;
  onChange: (field, option, value) => void;
  onDelete: (field, option) => void;
  onAdd: (field: InputField) => void;
};

const OptionsConfig = ({ field, onChange, onAdd, onDelete }: OptionsConfigProps) => (
  <>
    <Group mt={16}>
      <Text size="sm">Options</Text>
      <ActionIcon variant="filled" aria-label="Settings" onClick={() => onAdd(field)}>
        <IconPlus color='green' size={14} />
      </ActionIcon>
    </Group>
    <OptionsBuilder
      options={field.options ?? []}
      onChange={({ option, change }) => onChange(field, option, change)}
      onDelete={(option: InputOption) => onDelete(field, option)}
    />
  </>
);

export default FieldConfiguration;
