import React, { useState } from 'react';
import { ActionIcon, Box, Container, Flex } from '@mantine/core';
import { LeftNav } from '../CollapsibleBrowser/LeftNav';
import { mainNavigation } from '../../routes/index.routes';
import Footer from '../Footer';
import { Header } from '../Header';
import styles from '../../styles/screens/nav-container.module.scss';
import useStyles from './useStyles';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

const NavigationContainer: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [isOpen, setIsOpen] = useState(true);
	const { classes, cx } = useStyles();
	const toogleOpen = () => {
		setIsOpen((bef) => !bef);
	};

	return (
		<Container className={styles.root} bg="dark.5">
			<Header />
			<nav className={styles.nav}>
				{isOpen ? <LeftNav routes={mainNavigation} /> : <Box w={30} />}
				<ActionIcon
					variant="filled"
					radius={'50%'}
					className={cx(styles.expanded, classes.expanded)}
					onClick={toogleOpen}>
					{isOpen ? <IconChevronLeft /> : <IconChevronRight />}
				</ActionIcon>
			</nav>

			<Flex className={styles.main}>{children}</Flex>
			<Footer />
		</Container>
	);
};

export default NavigationContainer;
