import * as TablerIcons from '@tabler/icons-react';
import { Button } from '@mantine/core';

interface AddFieldButtonProps {
  title: string;
  icon: string;
  onPress: () => void;
}

const AddFieldButton = ({ title, icon, onPress }: AddFieldButtonProps) => {
  const Icon = TablerIcons[icon] ?? TablerIcons.Icon123;

  return (
    <Button color="indigo" size="sm" variant="light" leftIcon={<Icon />} onClick={onPress}>
      {title}
    </Button>
  );
};

export default AddFieldButton;
