import { Navbar, ScrollArea } from '@mantine/core';
import { LinksGroup } from './NavbarLinksGroup';
import { FC } from 'react';
import { Route } from '../../types/routes.type';
import { useLocation } from 'react-router-dom';
import { useStylesLeftNav } from './useStyles';

export const LeftNav: FC<{ routes: Route[] }> = ({ routes }) => {
	const { classes } = useStylesLeftNav();
	const location = useLocation();

	const links = routes.map((item) => (
		<LinksGroup {...item} key={item.label} currentPath={location.pathname} />
	));

	return (
		<Navbar
			width={{ sm: 250 }}
			height={'100%'}
			p="md"
			className={classes.navbar}>
			<Navbar.Section grow className={classes.links} component={ScrollArea}>
				<div className={classes.linksInner}>{links}</div>
			</Navbar.Section>
		</Navbar>
	);
};
