import { Stack, TextInput } from '@mantine/core';
import { InputField } from '../../../types/Forms';
import Actions from './Common/Actions';
import { InputBuilderProps } from './Common/Types';
import { getId } from '../FieldConfiguration/Common/Utils';
import { styles } from './Common/Styles';

export const getExampleHidden = (): InputField => ({
	label: 'Campaign Value',
	type: 'hidden',
	key: `campaign-${getId()}`,
	name: `campaign-${getId()}`,
	description: 'This value is hidden to the user, enter the value to track.',
	placeholder: 'Your campaign key or id',
	validation: {
		required: true,
	},
});

const HiddenTextBuilder = ({
	field,
	isEditing,
	onEditing,
	onDeleting,
	isSignup,
	onChangeText,
}: InputBuilderProps) => {
	if (isSignup) {
		return <></>;
	}

	return (
		<Stack style={isEditing ? styles.containerEditing : styles.container}>
			<TextInput
				type="text"
				label={field.label}
				value={field.value ?? ''}
				description={field.description}
				placeholder={field.placeholder}
				onChange={(event) => {
					const value = event.currentTarget.value;
					onChangeText({ ...field, value });
				}}
			/>

			<Actions onEditing={onEditing} onDeleting={onDeleting} />
		</Stack>
	);
};

export default HiddenTextBuilder;
