import React from 'react';
import {
	TextInput,
	PasswordInput,
	Text,
	Paper,
	Button,
	Checkbox,
	Stack,
	Image,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useToggle, upperFirst } from '@mantine/hooks';
import { validateEmail, validPassword } from '../utils';
import styles from '../styles/screens/login.module.scss';
import LogoImg from '../assets/images/logo.png';
import useSession from '../hooks/useSession';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
	const [type] = useToggle(['login', 'register']);
	const { onLogin, loading } = useSession();
	const navigate = useNavigate();
	const form = useForm({
		initialValues: {
			email: '',
			name: '',
			password: '',
			terms: true,
		},
		validate: {
			email: (val) => (validateEmail(val) ? null : 'Invalid email'),
			password: (val) => (validPassword(val) ? null : 'Invalid password'),
		},
	});

	const handleSubmit = async (values: typeof form.values) => {
		await onLogin({ email: values.email, password: values.password });
	};
	const onForgotPassword = () => {
		navigate('/forgot-password');
	};

	return (
		<Paper radius="md" p="xl" className={styles.content}>
			<Image width={42} height={42} src={LogoImg} alt="Shockoe Admin" />

			<Text className={styles.title}>Welcome</Text>

			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					{type === 'register' && (
						<TextInput
							label="Name"
							placeholder="Your name"
							value={form.values.name}
							onChange={(event) =>
								form.setFieldValue('name', event.currentTarget.value)
							}
							radius="md"
						/>
					)}

					<TextInput
						required
						label="Email"
						placeholder="Enter your email"
						value={form.values.email}
						onChange={(event) =>
							form.setFieldValue('email', event.currentTarget.value)
						}
						error={form.errors.email}
						radius="md"
					/>

					<PasswordInput
						required
						label="Password"
						placeholder="Enter your password"
						value={form.values.password}
						onChange={(event) =>
							form.setFieldValue('password', event.currentTarget.value)
						}
						error={form.errors.password}
						radius="md"
					/>

					{type === 'register' && (
						<Checkbox
							label="I accept terms and conditions"
							checked={form.values.terms}
							onChange={(event) =>
								form.setFieldValue('terms', event.currentTarget.checked)
							}
						/>
					)}
				</Stack>

				<Button
					mt={16}
					loading={loading === 'pending'}
					disabled={loading === 'pending'}
					fullWidth
					type="submit"
					radius="md"
					color="indigo">
					{upperFirst(type)}
				</Button>

				<Button
					mt={16}
					fullWidth
					type="button"
					radius="md"
					onClick={() => navigate('/register')}
					variant="light"
					color="indigo">
					{type === 'register'
						? 'Already have an account? Login'
						: 'Create my account'}
				</Button>
				<Button
					mt={16}
					fullWidth
					type="button"
					radius="md"
					onClick={onForgotPassword}
					variant="outline"
					color="indigo">
					I forgot my password
				</Button>
			</form>
		</Paper>
	);
};

export default Login;
