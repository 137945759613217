import React from 'react';
import {
  Box,
  Group,
  Image,
  Stack,
  TextInput,
} from '@mantine/core';

const FormInformation = ({ title, description, image, onChangeTitle, onChangeDescription, onChangeImage }) => (
  <Stack style={{ padding: 8 }}>
    <TextInput
      label="Title"
      placeholder="This is your campaign title"
      value={title}
      onChange={(event) =>
        onChangeTitle(event.currentTarget.value)
      }
      radius="md"
      required
    />
    <TextInput
      label="Description"
      placeholder="This is your campaign description"
      value={description}
      onChange={(event) =>
        onChangeDescription(event.currentTarget.value)
      }
      radius="md"
    />
    <Group>
      <TextInput
        label="Image"
        placeholder="This is your image logo rendered at the top"
        value={image}
        onChange={(event) =>
          onChangeImage(event.currentTarget.value)
        }
        radius="md"
        style={{ flex: 1 }}
      />
      {image ? (
        <Group w={200} position="center">
          <Box w={100}>
            <Image src={image} />
          </Box>
        </Group>
      ) : null}
    </Group>

  </Stack>
);

export default FormInformation;
