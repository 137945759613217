import { InputField } from '../../../types/Forms';
import { InputBuilderProps } from './Common/Types';
import { Radio, Group, Stack } from '@mantine/core';
import Actions from './Common/Actions';
import { getId } from '../FieldConfiguration/Common/Utils';
import { styles } from './Common/Styles';

export const getExampleRadio = (): InputField => ({
  label: "Preferred Programming Language",
  type: 'radio',
  key: `radio-${getId()}`,
  name: `favoritelang-${getId()}`,
  description: "Select your favorite programming language",
  options: [
    {
      key: getId(),
      label: "JavaScript",
      value: "javascript"
    },
    {
      key: getId(),
      label: "Python",
      value: "python"
    },
    {
      key: getId(),
      label: "Java",
      value: "java"
    }
  ],
  validation: {
    required: false,
  },
});

const RadioBuilder = ({
	field,
	isEditing,
	onEditing,
	onDeleting,
	isSignup,
	onChangeText,
}: InputBuilderProps) => {
	const { value, label, description, options, validation } = field;

	return (
		<Stack style={isEditing ? styles.containerEditing : styles.container}>
			<Radio.Group
				label={label}
				description={description}
				withAsterisk={validation.required}
				value={value as unknown as string}
				onChange={(event) => {
					onChangeText?.({ ...field, value: event });
				}}
			>
				<Group mt="xs">
					{options.map((option) => (
						<Radio
							key={option.value}
							value={option.value}
							label={option.label}
							disabled={!isSignup}
						/>
					))}
				</Group>
			</Radio.Group>

			{!isSignup ? (
				<Actions onEditing={onEditing} onDeleting={onDeleting} />
			) : undefined}
		</Stack>
	);
};

export default RadioBuilder;
