import { InputField } from '../../../types/Forms';
import { InputBuilderProps } from './Common/Types';
import { Stack, TextInput } from '@mantine/core';
import Actions from './Common/Actions';
import { getId } from '../FieldConfiguration/Common/Utils';
import { styles } from './Common/Styles';

export const getExampleEmail = (): InputField => ({
	label: 'Email',
	type: 'email',
	key: `email-${getId()}`,
	name: `email-${getId()}`,
	description: 'Enter your email address',
	placeholder: 'you@example.com',
	validation: {
		required: true,
		pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
	},
});

const EmailBuilder = ({
	field,
	isEditing,
	onEditing,
	onDeleting,
	isSignup,
	onChangeText,
}: InputBuilderProps) => {
	const { value, label, description, placeholder, validation } = field;

	return (
		<Stack style={isEditing ? styles.containerEditing : styles.container}>
			<TextInput
				type="email"
				label={label}
				description={description}
				placeholder={placeholder}
				required={validation.required}
				minLength={validation.minLength}
				maxLength={validation.maxLength}
				pattern={validation.pattern}
				value={value}
				onChange={(event) => {
					const value = event.currentTarget.value;
					onChangeText?.({ ...field, value });
				}}
				disabled={!isSignup}
			/>

			{!isSignup ? (
				<Actions onEditing={onEditing} onDeleting={onDeleting} />
			) : undefined}
		</Stack>
	);
};

export default EmailBuilder;
