import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MantineProvider } from '@mantine/core';
import { Toaster } from 'react-hot-toast';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<Toaster position="top-right" reverseOrder={true} />
		<MantineProvider theme={{ colorScheme: 'dark' }} withCSSVariables>
			<App />
		</MantineProvider>
	</React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
