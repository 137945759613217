import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import Login from './screens/Login'; // Import your Login component
import Registration from './screens/Registration'; // Import your Login component
import FormBuilder from './screens/FormBuilder';
import Campaigns from './screens/Campaigns';
import Entries from './screens/Entries';
import SignupCampaign from './screens/SignUpCampaign';

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Registration />} />
				<Route path="/formbuilder" element={<FormBuilder />} />
				<Route path="/campaigns" element={<Campaigns />} />
				<Route path="/entries" element={<Entries />} />
				<Route
					path="/sign-up-campaign/:formId"
					element={<SignupCampaign />}
				/>
				<Route path="/" element={<Navigate to="/login" />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
