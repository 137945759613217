import React, { useEffect } from 'react';
import NavigationContainer from '../components/NavigationContainer';
import FormInformation from '../components/FormBuilder/FormInformation';
import {
	Anchor,
	Button,
	Divider,
	Group,
	Loader,
	Paper,
	ScrollArea,
	Stack,
	Text,
	Title,
} from '@mantine/core';
import { getExampleInput } from '../components/FormBuilder/FieldRenderer/InputTextBuilder';
import FormRenderer from '../components/FormBuilder/FormRenderer';
import FieldConfiguration from '../components/FormBuilder/FieldConfiguration';
import { getExampleEmail } from '../components/FormBuilder/FieldRenderer/EmailBuilder';
import { getExamplePassword } from '../components/FormBuilder/FieldRenderer/PasswordBuilder';
import { getExampleDate } from '../components/FormBuilder/FieldRenderer/DateBuilder';
import { getExampleCheckbox } from '../components/FormBuilder/FieldRenderer/CheckboxBuilder';
import { getExampleRadio } from '../components/FormBuilder/FieldRenderer/RadioBuilder';
import { getExampleSelect } from '../components/FormBuilder/FieldRenderer/SelectBuilder';
import { getExampleTextarea } from '../components/FormBuilder/FieldRenderer/TextareaBuilder';
import { InputField, InputType } from '../types/Forms';
import { useLocation } from 'react-router-dom';
import { isEmpty, isString } from '../utils';
import { getExampleHidden } from '../components/FormBuilder/FieldRenderer/HiddenBuilder';
import toast from 'react-hot-toast';
import useCreateCampaign from '../hooks/screens/useCreateCampaign';
import InputPicker from '../components/FormBuilder/InputPicker';

const FormBuilder: React.FC = () => {
	const { form, fieldEditing, loading, fetchForm, setForm, setFieldEditing, onHandleCreate, onHandleUpdate, checkFormErrors } = useCreateCampaign();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const formId = queryParams.get('id');
	const isValidFormId = isString(formId) && !isEmpty(formId);
	const screenTitle = isValidFormId ? 'Edit campaign' : 'Create new campaign';

	useEffect(() => {
		if (isValidFormId) {
			fetchForm(formId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Event when admin selects one input type from the sidebar and adds a new field
	 * @param input field Input JSON
	 */
	const onAddInput = (input: InputType) => {
		const sections = [...form.sections];

		switch (input) {
			case 'text':
				sections.push(getExampleInput());
				break;
			case 'email':
				sections.push(getExampleEmail());
				break;
			case 'password':
				sections.push(getExamplePassword());
				break;
			case 'date':
				sections.push(getExampleDate());
				break;
			case 'checkbox':
				sections.push(getExampleCheckbox());
				break;
			case 'radio':
				sections.push(getExampleRadio());
				break;
			case 'select':
				sections.push(getExampleSelect());
				break;
			case 'textarea':
				sections.push(getExampleTextarea());
				break;
			case 'hidden':
				sections.push(getExampleHidden());
				break;
			default:
				break;
		}

		setForm({ ...form, sections });
	};

	/**
	 * Event when admin removes the field from the list
	 * @param field Input JSON
	 */
	const onDeleteField = (field: InputField) => {
		const _form = { ...form };

		const index = _form.sections.findIndex((el) => el.key === field.key);

		if (index !== -1) {
			_form.sections.splice(index, 1);
			setForm({ ..._form });
		}

		setFieldEditing(null);
	};

	/**
	 * Event when admin changes the field configuration
	 * @param field Input JSON
	 */
	const onChangeConfiguration = (field: InputField) => {
		const _form = { ...form };
		const index = _form.sections.findIndex((el) => el.key === field.key);

		const existWithName = _form.sections.findIndex(
			(el) => el.name === field.name && el.key !== field.key,
		);

		if (existWithName >= 0) {
			toast.error(
				"It's seemed that you have already a field with this name, please enter a unique name per field",
			);

			return;
		}

		if (index !== -1) {
			_form.sections[index] = { ...field };
			setForm({ ..._form });
			setFieldEditing({ ...field });
		}
	};

	const onSubmit = async () => {
		const errors = checkFormErrors();

		if (errors.length > 0) {
			toast.error(
				errors,
			);
			return;
		}

		let response = null;

		if (isValidFormId) {
			response = await onHandleUpdate(formId);
		} else {
			response = await onHandleCreate();
		}

		if (response === null) {
			toast.error(
				"Something went wrong, try again.",
			);
		} else {
			if (isValidFormId) {
				toast.success("Campaign updated");
			} else {
				window.location.href = '/campaigns';
			}
		}
	};

	return (
		<NavigationContainer>
			{loading ? (
				<Group position="center" my={40}>
					<Loader color="orange" />
				</Group>
			) : (
				<Paper bg="dark.5">
					<Stack p={16}>
						<Group position="apart">
							<Title>{screenTitle}</Title>
							<Button color="orange" variant="light" type="button" mt={8} mb={8} onClick={onSubmit}>
								<Anchor color="orange">
									{isValidFormId ? 'Update Campaign' : 'Create Campaign'}
								</Anchor>
							</Button>
						</Group>
						<FormInformation
							title={form.title}
							description={form.description}
							image={form.image}
							onChangeTitle={(title) => setForm({ ...form, title })}
							onChangeDescription={(description) =>
								setForm({ ...form, description })
							}
							onChangeImage={(image) => setForm({ ...form, image })}
						/>
						<Divider />
						<InputPicker onAddInput={onAddInput} />
					</Stack>
					<Group position="center">
						<Stack ml={16} p={16}>
							<Title order={4} mb={16}>Form Preview</Title>
							<Divider />
							{form.sections.length === 0 ? (
								<Stack w={400}>
									<Title order={6}>Empty form</Title>
									<Text size="sm">Select any input from the list above to start designing your form.</Text>
								</Stack>
							) : (
								<ScrollArea w={400} h={600}>
									{form.sections.map((input) => (
										<FormRenderer
											key={input.key}
											type={input.type}
											field={input}
											onEditing={(field) => setFieldEditing(field)}
											onChangeText={(field) => onChangeConfiguration(field)}
											onDeleting={onDeleteField}
											isEditing={input.key === fieldEditing?.key}
										/>
									))}
								</ScrollArea>
							)}
						</Stack>
						{fieldEditing !== null ? (
							<Stack ml={32} style={{ flex: 1 }}>
								<FieldConfiguration
									field={fieldEditing}
									type={fieldEditing.type}
									onChangeConfiguration={onChangeConfiguration}
								/>
							</Stack>
						) : null}
					</Group>
				</Paper>
			)}
		</NavigationContainer>
	);
};

export default FormBuilder;
