/* eslint-disable no-unused-vars */
import { User } from '../../types/placeholderUser.type';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { loadingType } from '../../types/common';
import { LoginProps, postLogin } from '../../services/auth';

interface SessionContextProps {
	user: User | undefined;
	loading: loadingType;
	token: string;
	loginUser: (values: LoginProps) => Promise<void>;
	logout: () => void;
}
const initialState = {
	user: undefined,
	loading: 'idle' as loadingType,
	token: '',
};
export const SessionStorageKey = 'session';

const useSessionState = create<SessionContextProps>()(
	persist(
		(set) => ({
			...initialState,
			loginUser: async ({ email, password }) => {
				try {
					set({ loading: 'pending' });
					const user = await postLogin({ email, password });
					set({ user });
				} catch (error) {
					console.error(error)
					set({ loading: 'failed' });
				} finally {
					set({ loading: 'idle' });
				}
			},
			logout: () => set({ ...initialState }),
		}),
		{
			name: SessionStorageKey,
			storage: createJSONStorage(() => localStorage),
		},
	),
);

export const clearSession = () => {
	useSessionState.setState({ ...initialState });
	useSessionState.persist.clearStorage();
};

export default useSessionState;

export const mockUserSession = async () => {
	const user = {
		id: '1001',
		name: 'Shockoe Admin',
		username: 'shockoe',
		email: 'admin@shockoe.com',
		address: {
			street: '1234 Main street',
			suite: '',
			city: 'Richmond',
			zipcode: 12345,
			geo: {
				lat: 0,
				lng: 0
			},
		},
		phone: '+1 12 1122 7878',
		website: 'https://shockoe.com',
		company: {
			name: 'Shockoe',
			catchPhrase: 'Mobile by design',
			bs: ''
		},
		image: 'https://shockoe.com/wp-content/uploads/2023/06/orange-shockoe-logo-01.svg'
	};

	const token = process.env.AUTH_TOKEN;

	console.log(token);
	console.log(user);
};