import React from 'react';
import { Box, Button, Image, Loader, Paper, Stack, Text } from '@mantine/core';
import useSignupCampaign from '../hooks/screens/useSignUpCampaign';
import styles from '../styles/screens/signupcampaing.module.scss';
import FormRenderer from '../components/FormBuilder/FormRenderer';
import { FormSchema } from '../types/Forms';

const SignupCampaign: React.FC = () => {
	const { loading, error, successfulJoin, campaginInfo, form, handleSubmit } =
		useSignupCampaign();

	if (loading) {
		return (
			<Paper radius="md" p="xl" className={styles.content}>
				<Loader color="orange" />
			</Paper>
		);
	}

	if (error) {
		return (
			<Paper radius="md" p="xl" className={styles.content}>
				<HeaderContent campaginInfo={campaginInfo} />
				<Text className={styles.title}>{error}</Text>
			</Paper>
		);
	}

	if (successfulJoin) {
		return (
			<Paper radius="md" p="xl" className={styles.content}>
				<HeaderContent campaginInfo={{ ...campaginInfo, description: null }} />
				<Text className={styles.description} mt={8}>
					Thank you!
				</Text>
			</Paper>
		);
	}

	return (
		<Paper radius="md" p="xl" className={styles.content}>
			<HeaderContent campaginInfo={campaginInfo} />

			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack spacing="sm">
					{(campaginInfo?.sections || []).map((input) => {
						return (
							<FormRenderer
								isSignup
								isEditing={false}
								key={input.key}
								type={input.type}
								field={{
									...input,
									value: form.values?.[input.name],
								}}
								onChangeText={({ value }) => {
									form.setFieldValue(input.name, value);
								}}
							/>
						);
					})}
				</Stack>

				<Button
					mt={16}
					loading={loading}
					disabled={loading}
					fullWidth
					type="submit"
					radius="md"
					color="indigo">
					SUBMIT
				</Button>
			</form>
		</Paper>
	);
};

const HeaderContent = ({ campaginInfo }: { campaginInfo?: FormSchema }) => {
	return (
		<Stack className={styles.headerContent}>
			{campaginInfo?.image ? (
				<Box w={300}>
					<Image src={campaginInfo?.image} />
				</Box>
			) : undefined}

			{campaginInfo?.title ? (
				<Box w={300}>
					<Text align="center" className={styles.title}>{campaginInfo?.title}</Text>
				</Box>
			) : undefined}

			{campaginInfo?.description ? (
				<Box w={300}>
					<Text align="justify" className={styles.description}>{campaginInfo?.description}</Text>
				</Box>
			) : undefined}
		</Stack>
	);
};

export default SignupCampaign;
