import React from 'react';
import { ActionIcon, Group } from '@mantine/core';
import { IconSettings, IconTrash } from '@tabler/icons-react';

interface ActionsProps {
  onEditing: () => void;
  onDeleting: () => void;
}

const Actions = ({ onEditing, onDeleting }: ActionsProps) => (
  <Group style={{ justifyContent: 'flex-end' }}>
    <ActionIcon onClick={onEditing} variant="filled" aria-label="Settings">
      <IconSettings size={14} />
    </ActionIcon>
    <ActionIcon onClick={onDeleting} variant="filled" color='red'>
      <IconTrash size={14} />
    </ActionIcon>
  </Group>
);

export default Actions;
