import React from 'react';
import { InputField } from '../../../types/Forms';
import { InputBuilderProps } from './Common/Types';
import { Stack, Select } from '@mantine/core';
import Actions from './Common/Actions';
import { getId } from '../FieldConfiguration/Common/Utils';
import { styles } from './Common/Styles';

export const getExampleSelect = (): InputField => ({
  label: "Gender",
  type: 'select',
  key: `select-${getId()}`,
  name: `gender-${getId()}`,
  description: 'Select your gender',
  options: [
    {
      key: getId(),
      label: "Male",
      value: "male"
    },
    {
      key: getId(),
      label: "Female",
      value: "female"
    },
    {
      key: getId(),
      label: "Other",
      value: "other"
    }
  ],
  validation: {
    required: false,
  },
});

const SelectBuilder = ({
	field,
	isEditing,
	onEditing,
	onDeleting,
	isSignup,
	onChangeText,
}: InputBuilderProps) => {
	const { value, label, description, options, validation } = field;

	return (
		<Stack style={isEditing ? styles.containerEditing : styles.container}>
			<Select
				label={label}
				placeholder={description}
				value={value as unknown as string}
				data={options.map((o) => o.label)}
				required={validation.required}
				onChange={(event) => {
					const value = event;
					onChangeText?.({ ...field, value });
				}}
        disabled={!isSignup}
			/>

			{!isSignup ? (
				<Actions onEditing={onEditing} onDeleting={onDeleting} />
			) : undefined}
		</Stack>
	);
};

export default SelectBuilder;
