import Config from '../Config';

const headers = {
	Authorization: process.env.REACT_APP_AUTH_TOKEN,
	'Content-Type': 'application/json',
};
const method = 'POST';

const fetchAPI = async (request: any) => {
	return await fetch(Config.baseUrl, {
		body: JSON.stringify(request),
		headers,
		method,
	});
};

/**
 * Forms
 */

export const getAllForms = async ({ userId }: { userId: string }) => {
	const request = {
		action: 'get_forms',
		payload: {
			userId,
		},
	};

	try {
		const response = await fetchAPI(request);
		return await response.json();
	} catch (exception) {
		console.error(exception);
		return [];
	}
};

export const getFormById = async ({ formId }: { formId: string }) => {
	const request = {
		action: 'get_form',
		payload: {
			formId,
		},
	};

	try {
		const response = await fetchAPI(request);
		const data = await response.json();
		const { status } = response;

		if (status >= 400) {
			console.error(data);
			throw Error(data);
		}

		return data;
	} catch (exception) {
		console.error(exception);
		return null;
	}
};

export const createForm = async ({
	userId,
	formSchema,
}: {
	userId: string;
	formSchema: any;
}) => {
	const request = {
		action: 'create_form',
		payload: {
			userId,
			formSchema,
		},
	};

	try {
		const response = await fetchAPI(request);
		return await response.json();
	} catch (exception) {
		console.error(exception);
		return null;
	}
};

export const updateForm = async ({
	formId,
	formSchema,
}: {
	formId: string;
	formSchema: any;
}) => {
	const request = {
		action: 'update_form',
		payload: {
			formId,
			formSchema,
		},
	};

	try {
		const response = await fetchAPI(request);
		return await response.json();
	} catch (exception) {
		console.error(exception);
		return null;
	}
};

export const deleteForm = async ({ formId }: { formId: string }) => {
	const request = {
		action: 'delete_form',
		payload: {
			formId,
		},
	};

	try {
		const response = await fetchAPI(request);
		return await response.json();
	} catch (exception) {
		console.error(exception);
		return null;
	}
};

/**
 * Entries
 */

export const getAllEntriesForFormId = async ({
	formId,
}: {
	formId: string;
}) => {
	const request = {
		action: 'get_entries',
		payload: {
			formId,
		},
	};

	try {
		const response = await fetchAPI(request);
		return await response.json();
	} catch (exception) {
		console.error(exception);
		return [];
	}
};

export const getEntry = async ({ entryId }: { entryId: string }) => {
	const request = {
		action: 'get_entry',
		payload: {
			entryId,
		},
	};

	try {
		const response = await fetchAPI(request);
		return await response.json();
	} catch (exception) {
		console.error(exception);
		return null;
	}
};

export const createEntry = async ({
	formId,
	formData,
}: {
	formId: string;
	formData: any;
}) => {
	const request = {
		action: 'create_entry',
		payload: {
			formId,
			formData,
		},
	};

	try {
		const response = await fetchAPI(request);

		const data = await response.json();
		const { status } = response;

		if (status >= 400) {
			console.error(data);
			throw Error(data);
		}

		return data;
	} catch (exception) {
		console.error(exception);
		return null;
	}
};

export const deleteEntry = async ({ entryId }: { entryId: string }) => {
	const request = {
		action: 'delete_entry',
		payload: {
			entryId,
		},
	};

	try {
		const response = await fetchAPI(request);
		return await response.json();
	} catch (exception) {
		console.error(exception);
		return null;
	}
};
