import { useNavigate } from 'react-router-dom';
import { LoginProps } from '../services/auth';
import useSessionState from '../states/useAuthState';

const useSession = () => {
	const { loading, user, loginUser, logout } = useSessionState();
	const navigate = useNavigate();
	const onLogin = async (loginProps: LoginProps) => {
		await loginUser(loginProps);
		if (loading === 'failed') {
			alert('Something was wrong');
			return;
		}
		navigate('/entries');
	};
	const onLogout = () => {
		logout();
		navigate('/login');
	};
	return { onLogin, onLogout, user, loading };
};

export default useSession;
