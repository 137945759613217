import dayjs from 'dayjs';

/**
 * Validates an email address using regular expression.
 * @param email - The email address to be validated.
 * @returns True if the email address is valid, otherwise false.
 */
export const validateEmail = (email) => {
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailRegex.test(email.toLowerCase());
};

/**
 * Validates an password using regular expression.
 * Details:
 * - One capital letter
 * - One number
 * - Minimum 8 characters
 * - Maximum 8 characters
 * @param pass - The password to be validated.
 * @returns True if the password is valid, otherwise false.
 */
export const validPassword = (pass) => {
	const passRegex = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
	return passRegex.test(pass);
};

/**
 * Formats a given date to a specified format.
 * @param date - The date to be formatted.
 * @param format - The format to be applied. Defaults to 'MMM DD YYYY'.
 * @returns The formatted date.
 */
export const formatDate = (date, format = 'MMM D YYYY') => {
	return dayjs(date).format(format);
};

/**
 * Converts a Unix timestamp to a formatted date.
 * @param date - The Unix timestamp to be converted.
 * @param format - The format to be applied. Defaults to 'MMM DD YYYY'.
 * @returns The formatted date.
 */
export const timestampToDate = (date, format = 'MMMM D YYYY') => {
	return dayjs.unix(date).format(format);
};

/**
 * Capitalizes the first letter of a string.
 * @param value - The string to be capitalized.
 * @returns The string with the first letter capitalized.
 */
export const upperFirst = (value) => {
	return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Formats a phone number by adding dashes at appropriate positions.
 * @param phoneNumber - The phone number to be formatted.
 * @returns The formatted phone number.
 */
export const formatPhoneNumber = (phoneNumber) => {
	const cleaned = ('' + phoneNumber).replace(/\D/g, '');
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		const dashSeparator = '-';
		return [match[1], dashSeparator, match[2], dashSeparator, match[3]].join(
			'',
		);
	}
	return cleaned;
};

/**
 * Truncates a string if it exceeds a specified length.
 * @param str - The string to be truncated.
 * @param num - The maximum length of the string.
 * @returns The truncated string with ellipsis.
 */
export const truncateString = (str, num) => {
	if (str.length <= num) {
		return str;
	}
	return str.slice(0, num) + '...';
};

/**
 * Checks if a start date is after an end date.
 * @param startDate - The start date to be compared.
 * @param endDate - The end date to be compared. Defaults to the current date.
 * @returns True if the start date is after the end date, otherwise false.
 */
export const isAfterDate = (startDate, endDate = new Date()) => {
	return new Date(startDate).valueOf() > new Date(endDate).valueOf();
};

/**
 * Takes an object and creates a deep clone of it by converting it to a JSON string and then parsing it back to an object
 * @param {object} obj - The object to be cloned
 * @returns {object} - The deep clone of the object
 */
export const deepClone = (obj) => {
	return JSON.parse(JSON.stringify(obj));
};

/**
 * Checks if a value is empty or not
 * @param {*} value - The value to be checked
 * @returns {boolean} - True if the value is empty, false otherwise
 */
export const isEmpty = (value) => {
	return (
		value == null ||
		(typeof value === 'object' && Object.keys(value).length === 0) ||
		value === ''
	);
};

/**
 * Checks if a value is an object (excluding null and arrays).
 * @param {any} value - The value to check.
 * @return {boolean} - True if the value is an object (excluding null and arrays), false otherwise.
 */
export const isObject = (value) => {
	return typeof value === 'object' && value !== null && !Array.isArray(value);
};

/**
 * Checks if a value is an array.
 * @param {any} value - The value to check.
 * @return {boolean} - True if the value is an array, false otherwise.
 */
export const isArray = (value) => {
	return Array.isArray(value);
};

/**
 * Checks if a value is a string.
 * @param {any} value - The value to check.
 * @return {boolean} - True if the value is a string, false otherwise.
 */
export const isString = (value) => {
	return typeof value === 'string';
};

/**
 * Checks if a value is a function.
 * @param {any} value - The value to check.
 * @return {boolean} - True if the value is a function, false otherwise.
 */
export const isFunction = (value) => {
	return typeof value === 'function';
};

/**
 * Debounces the execution of a function by delaying it for a certain period (specified in milliseconds).
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The delay in milliseconds.
 * @return {Function} - The debounced function.
 */
export const debounce = (func, delay) => {
	let timer;
	return function (...args) {
		clearTimeout(timer);
		timer = setTimeout(() => func.apply(this, args), delay);
	};
};

/**
 * Parses a string representation of a date and returns a Date object.
 * @param {string} dateString - The string representation of a date.
 * @returns {Date} - The parsed Date object.
 * @throws {Error} - If the string is not a valid date.
 */
export const parseDate = (dateString) => {
	const date = new Date(dateString);
	if (isNaN(date.getTime())) {
		throw new Error('Invalid date string');
	}
	return date;
};

/**
 * Calculates the sum of all the numbers in an array.
 * @param {number[]} arr - The array of numbers.
 * @returns {number} - The sum of all the numbers.
 */
export const sumArray = (arr) => {
	return arr.reduce((acc, curr) => acc + curr, 0);
};

/**
 * Generates a Universally Unique Identifier (UUID).
 * @returns {string} - The generated UUID.
 */
export const generateUUID = () => {
	let dt = new Date().getTime();
	const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		// eslint-disable-next-line no-bitwise
		const r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		// eslint-disable-next-line no-bitwise
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
};

/**
 * Parses a URL string and returns an object representing the query parameters.
 * @param {string} url - The URL string.
 * @returns {Object} - An object representing the query parameters.
 */
export const parseQueryString = (url) => {
	const queryString = url.split('?')[1];
	if (!queryString) return {};
	const params = new URLSearchParams(queryString);
	const queryObj = {};
	for (const [key, value] of params.entries()) {
		queryObj[key] = value;
	}
	return queryObj;
};
