
export const styles = {
  container: {
    marginTop: 16,
    padding: 8
  },
  containerEditing: {
    marginTop: 16,
    padding: 8,
    backgroundColor: '#748FFC50',
    borderRadius: 4,
  },
};
