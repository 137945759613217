import React from 'react';
import { ActionIcon, Group, Stack, TextInput } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { InputOption } from '../../../../types/Forms';

interface OptionsBuilderProps {
  options: InputOption[];
  onDelete: (option: InputOption) => void;
  onChange: ({ option, change }: { option: InputOption; change: { key: string; value: string; } }) => void;
};

interface OptionBuilderProps {
  option: InputOption;
  onDelete: (option: InputOption) => void;
  onChange: ({ option, change }: { option: InputOption; change: { key: string; value: string; } }) => void;
};

export const OptionsBuilder = ({ options, onDelete, onChange }: OptionsBuilderProps) => (
  <Stack>
    {options.map(option => (
      <OptionBuilder
        key={option.key}
        option={option}
        onDelete={onDelete}
        onChange={onChange}
      />
    ))}
  </Stack>
);

const OptionBuilder = ({ option, onDelete, onChange }: OptionBuilderProps) => (
  <Group align='center'>
    <TextInput
      label="Label"
      placeholder="Javascript"
      value={option.label}
      required
      minLength={2}
      maxLength={100}
      onChange={(event) => onChange({ option, change: { key: 'label', value: event.currentTarget.value } })} />
    <TextInput
      label="Value"
      placeholder="js"
      value={option.value}
      required
      minLength={2}
      maxLength={100}
      onChange={(event) => onChange({ option, change: { key: 'value', value: event.currentTarget.value } })} />
    <ActionIcon
      variant="filled"
      aria-label="Delete"
      onClick={() => onDelete(option)}>
      <IconTrash color='red' size={14} />
    </ActionIcon>
  </Group>
);

export default OptionsBuilder;
