/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
	TextInput,
	PasswordInput,
	Select,
	Text,
	Paper,
	Button,
	Checkbox,
	Stack,
	Image,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import useRegisterUser from '../hooks/screens/useRegisterUser';
import AlertInfo from '../components/Alert/Info';
import styles from '../styles/screens/login.module.scss';
import LogoImg from '../assets/images/logo.png';

const Register: React.FC = () => {
	const navigate = useNavigate();
	const {
		statesList,
		wizard,
		toggleWizard,
		formEmail,
		formInfo,
		handleEmailSubmit,
		handleInfoSubmit,
		isLoading,
		notificationMessage,
		onCloseNotification,
		showNotification,
	} = useRegisterUser();

	return (
		<Paper radius="md" p="xl" className={styles.content}>
			<Image width={42} height={42} src={LogoImg} alt="Shockoe Admin" />

			<Text className={styles.title}>{wizard === 'email' ? 'Create your account' : 'Personal Information'}</Text>

			{wizard === 'email' && (
				<form onSubmit={formEmail.onSubmit(handleEmailSubmit)}>
					<Stack>
						<TextInput
							required
							label="Email"
							placeholder="Enter an email"
							value={formEmail.values.email}
							onChange={(event) =>
								formEmail.setFieldValue('email', event.currentTarget.value)
							}
							{...formEmail.getInputProps('email')}
							radius="md"
						/>

						<TextInput
							required
							label="Username"
							placeholder="Enter a username"
							value={formEmail.values.username}
							onChange={(event) =>
								formEmail.setFieldValue('username', event.currentTarget.value)
							}
							radius="md"
						/>

						<PasswordInput
							required
							label="Password"
							placeholder="Enter a password"
							value={formEmail.values.password}
							onChange={(event) =>
								formEmail.setFieldValue('password', event.currentTarget.value)
							}
							{...formEmail.getInputProps('password')}
							radius="md"
						/>

						<PasswordInput
							required
							label="Confirm Password"
							placeholder="Confirm your password"
							value={formEmail.values.confirmPassword}
							onChange={(event) =>
								formEmail.setFieldValue('confirmPassword', event.currentTarget.value)
							}
							radius="md"
							{...formEmail.getInputProps('confirmPassword')}
						/>
					</Stack>

					<Button
						mt={16}
						fullWidth
						type="submit"
						radius="md"
						color="indigo">
						Next
					</Button>

					<Button
						mt={16}
						fullWidth
						type="button"
						radius="md"
						onClick={() => navigate('/login')}
						variant="light"
						color="indigo">
						Back
					</Button>
				</form>
			)}

			{wizard === 'info' && (
				<form onSubmit={formInfo.onSubmit(handleInfoSubmit)}>
					<Stack>
						<TextInput
							required
							label="Name"
							placeholder="Enter name"
							value={formInfo.values.name}
							onChange={(event) =>
								formInfo.setFieldValue('name', event.currentTarget.value)
							}
							radius="md"
						/>

						<Select
							required
							searchable
							label="State"
							placeholder="Choose your state"
							value={formInfo.values.state}
							onChange={(event) =>
								formInfo.setFieldValue('state', event)
							}
							data={statesList}
						/>

						<TextInput
							required
							label="City"
							placeholder="Enter your city"
							value={formInfo.values.city}
							onChange={(event) =>
								formInfo.setFieldValue('city', event.currentTarget.value)
							}
							radius="md"
						/>

						<TextInput
							required
							label="Zip Code"
							placeholder="Enter your zip code"
							value={formInfo.values.zip}
							onChange={(event) =>
								formInfo.setFieldValue('zip', event.currentTarget.value)
							}
							radius="md"
						/>

						<TextInput
							required
							label="Street Address"
							placeholder="Enter your street address"
							value={formInfo.values.address}
							onChange={(event) =>
								formInfo.setFieldValue('address', event.currentTarget.value)
							}
							radius="md"
						/>

						<TextInput
							required
							label="Phone Number"
							placeholder="Enter your phone number"
							value={formInfo.values.phoneNumber}
							onChange={(event) =>
								formInfo.setFieldValue('phoneNumber', event.currentTarget.value)
							}
							radius="md"
						/>

						<Checkbox
							label="I accept terms and conditions"
							checked={formInfo.values.terms}
							onChange={(event) =>
								formInfo.setFieldValue('terms', event.currentTarget.checked)
							}
						/>
					</Stack>

					<Button
						loading={isLoading}
						mt={16}
						fullWidth
						type="submit"
						radius="md"
						color="indigo">
						Submit
					</Button>

					<Button
						mt={16}
						fullWidth
						type="button"
						radius="md"
						onClick={() => toggleWizard()}
						variant="light"
						color="indigo">
						Back
					</Button>
				</form>
			)}

			<AlertInfo
				{...notificationMessage}
				showNotification={showNotification}
				onClose={onCloseNotification}
			/>
		</Paper>
	);
};

export default Register;
