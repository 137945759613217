import { useEffect, useState } from 'react';
import useSession from '../useSession';
import {
	getAllEntriesForFormId,
	getAllForms,
} from '../../services/network/Formapi';
import { RemoteForm } from '../../types/Forms';
import { Entries } from '../../types/entries.types';
import toast from 'react-hot-toast';
import { saveAs } from 'file-saver';

const useEntries = () => {
	const {
		user: { id },
	} = useSession();

	const [loading, setLoading] = useState(false);
	const [selectedForm, setSelectedForm] = useState('0');

	const [forms, setForms] = useState<RemoteForm[]>([]);
	const [entries, setEntries] = useState<Entries>([]);

	useEffect(() => {
		if (id) {
			fetchCampaingsForLoggedInUser(id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const fetchCampaingsForLoggedInUser = async (userId: number) => {
		setLoading(true);
		const response = await getAllForms({ userId: `${userId}` });

		fetchEntriesByFormId(response[Number(selectedForm) || 0]?._id);

		setForms(response);
		setLoading(false);
	};

	const fetchEntriesByFormId = async (formId: string) => {
		setEntries([]);
		setLoading(true);

		const response = await getAllEntriesForFormId({ formId });

		setEntries(response);
		setLoading(false);
	};

	const handleChangeTab = (tab: string) => {
		const formInfo = forms.find((_, index) => index.toString() === tab);

		if (!formInfo) {
			return toast.error(
				'An error occurred while trying to obtain information try again.',
			);
		}

		setSelectedForm(tab);
		fetchEntriesByFormId(formInfo._id);
	};

	const handleSaveDataToJson = () => {
		const formInfo = forms.find(
			(_, index) => index.toString() === selectedForm,
		);

		if (!formInfo) {
			return toast.error(
				'An error occurred while trying to obtain information try again.',
			);
		}

		const formSchema = formInfo.formSchema;

		const json = JSON.stringify({ form: formInfo, entries });
		const blob = new Blob([json], { type: 'application/json' });

		// Save file using FileSaver.js
		saveAs(
			blob,
			`${formInfo._id}-${formSchema.title}-${new Date().getTime()}.json`,
		);

		// If you want to save the file in the project folder, you can't do it directly from the browser due to security restrictions.
		// You would need a server-side solution for that, such as a backend API to handle file saving.
	};

	const handleSaveDataToCsv = () => {
		const formInfo = forms.find(
			(_, index) => index.toString() === selectedForm,
		);

		if (!formInfo) {
			return toast.error(
				'An error occurred while trying to obtain information try again.',
			);
		}

		const formSchema = formInfo.formSchema;

		const header = formSchema.sections.map((section) =>
			section.name.charAt(0).toUpperCase() + section.name.slice(1)
		);
		const headerString = header.join(',');

		function replacer(key, value) {
			if (Array.isArray(value)) {
			  return value.join('|');
			}
			return value;
		}

		const rowItems = entries.map((row) => {
			return header
				.map((sectionName) => JSON.stringify(row["formData"][sectionName.toLowerCase()], replacer))
				.join(',')
		});

		const csv = [headerString, ...rowItems].join('\r\n');
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

		// Save file using FileSaver.js
		saveAs(
			blob,
			`${formInfo._id}-${formSchema.title}-${new Date().getTime()}.csv`,
		);
	};

	return {
		forms,
		entries,
		loading,
		selectedForm,
		handleChangeTab,
		handleSaveDataToJson,
		handleSaveDataToCsv,
	};
};

export default useEntries;
