import { FC } from 'react';
import { Text } from '@mantine/core';
import LogoLg from '../../assets/images/logo-lg.svg';
import useStyles from './useStyles';
import styles from '../../styles/screens/nav-container.module.scss';

interface FooterProps
	extends React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLElement>,
		HTMLElement
	> {
	year?: string;
}
const Footer: FC<FooterProps> = ({ ...props }) => {
	const { classes, cx } = useStyles();
	const year = `©${new Date().getFullYear()}`;
	return (
		<footer {...props} className={cx(classes.footer, styles.footer)}>
			<img alt="logo-lg" src={LogoLg} />
			<Text>
				{year},{' '}
				<a href="https://shockoe.com" target="_blank">
					SHOCKOE.COM LLC
				</a>
				, ALL RIGHT RESERVED
			</Text>
		</footer>
	);
};

export default Footer;
