import { InputField } from '../../../types/Forms';
import { InputBuilderProps } from './Common/Types';
import { Stack } from '@mantine/core';
import Actions from './Common/Actions';
import { DateInput } from '@mantine/dates';
import { getId } from '../FieldConfiguration/Common/Utils';
import { styles } from './Common/Styles';

export const getExampleDate = (): InputField => ({
	label: 'Date of Birth',
	type: 'date',
	key: `date-${getId()}`,
	name: `dob-${getId()}`,
	description: 'Select your date of birth',
	placeholder: '',
	validation: {
		required: false,
	},
});

const DateBuilder = ({
	field,
	isEditing,
	onEditing,
	onDeleting,
	isSignup,
	onChangeText,
}: InputBuilderProps) => {
	const { value, label, description, placeholder, validation } = field;

	return (
		<Stack style={isEditing ? styles.containerEditing : styles.container}>
			<DateInput
				label={label}
				description={description}
				placeholder={placeholder}
				required={validation.required}
				value={value ? new Date(value as unknown as string) : null}
				onChange={(event) => {
					const value = event.toISOString();
					onChangeText?.({ ...field, value });
				}}
				disabled={!isSignup}
			/>

			{!isSignup ? (
				<Actions onEditing={onEditing} onDeleting={onDeleting} />
			) : undefined}
		</Stack>
	);
};

export default DateBuilder;
