import { useState } from 'react';
import {
	Container,
	Avatar,
	UnstyledButton,
	Group,
	Text,
	Menu,
	rem,
} from '@mantine/core';
import { IconLogout, IconSettings } from '@tabler/icons-react';
import Logo from '../../assets/images/logo.png';
import useSession from '../../hooks/useSession';
import useStyles from './useStyles';

export function Header() {
	const { classes, theme, cx } = useStyles();
	const [userMenuOpened, setUserMenuOpened] = useState(false);
	const { onLogout, user } = useSession();

	return (
		<header className={classes.header}>
			<Container className={classes.mainSection}>
				<Group position="apart">
					<figure>
						<img width={28} src={Logo} />
					</figure>

					<Menu
						width={260}
						position="bottom-end"
						transitionProps={{ transition: 'pop-top-right' }}
						onClose={() => setUserMenuOpened(false)}
						onOpen={() => setUserMenuOpened(true)}
						withinPortal>
						<Menu.Target>
							<UnstyledButton
								className={cx(classes.user, {
									[classes.userActive]: userMenuOpened,
								})}>
								<Group spacing={7}>
									<Avatar
										src={user.image}
										alt={user.name}
										radius="xl"
										size={20}
									/>
									<Text
										weight={500}
										size="sm"
										sx={{ lineHeight: 1, color: theme.white }}
										mr={3}>
										{user.name}
									</Text>

									<IconSettings size={rem(24)} stroke={1.5} />
								</Group>
							</UnstyledButton>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								color="red"
								onClick={onLogout}
								icon={<IconLogout size="0.9rem" stroke={1.5} />}>
								Logout
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Group>
			</Container>
		</header>
	);
}
