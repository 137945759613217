/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { InputBuilderProps } from './Common/Types';
import { Checkbox, Group, Stack } from '@mantine/core';
import Actions from './Common/Actions';
import { getId } from '../FieldConfiguration/Common/Utils';
import { styles } from './Common/Styles';
import { InputField } from '../../../types/Forms';

export const getExampleCheckbox = (): InputField => ({
	label: "Hobbies",
	type: 'checkbox',
	key: `checkbox-${getId()}`,
	name: `hobbies-${getId()}`,
	description: "Select one or more hobbies",
	options: [
		{
			key: getId(),
			label: "Reading",
			value: "reading"
		},
		{
			key: getId(),
			label: "Gaming",
			value: "gaming"
		},
		{
			key: getId(),
			label: "Cooking",
			value: "cooking"
		}
	],
	validation: {
		required: false,
	},
});

const CheckboxBuilder = ({
	field,
	isEditing,
	onEditing,
	onDeleting,
	isSignup,
	onChangeText,
}: InputBuilderProps) => {
	const { value, label, description, options, validation } = field;

	return (
		<Stack style={isEditing ? styles.containerEditing : styles.container}>
			<Checkbox.Group
				label={label}
				description={description}
				withAsterisk={validation.required}
				value={value as unknown as string[]}
				onChange={(value) => {
					onChangeText?.({ ...field, value });
				}}>
				<Group mt="xs">
					{options.map((option) => (
						<Checkbox
							key={option.value}
							value={option.value}
							label={option.label}
							disabled={!isSignup}
						/>
					))}
				</Group>
			</Checkbox.Group>

			{!isSignup ? (
				<Actions onEditing={onEditing} onDeleting={onDeleting} />
			) : undefined}
		</Stack>
	);
};

export default CheckboxBuilder;
