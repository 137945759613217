import React, { useEffect, useState } from 'react';
import NavigationContainer from '../components/NavigationContainer';
import { Anchor, Button, Group, Loader, Title } from '@mantine/core';
import useSession from '../hooks/useSession';
import { deleteForm, getAllForms } from '../services/network/Formapi';
import { RemoteForm } from '../types/Forms';
import FormListing from '../components/FormListing';

const Campaigns: React.FC = () => {
	const {
		user: { id },
	} = useSession();
	const [forms, setForms] = useState<RemoteForm[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id) {
			fetchCampaingsForLoggedInUser(id);
		}
	}, [id]);

	const fetchCampaingsForLoggedInUser = async (userId: number) => {
		setLoading(true);
		const response = await getAllForms({ userId: `${userId}` });
		setForms(response);
		setLoading(false);
	};

	const onDelete = async (formId: string) => {
		setLoading(true);
		const response = await deleteForm({ formId });

		if (response !== null) {
			const _forms = [...forms];
			const deleteFormIndex = _forms.findIndex((el) => el._id === formId);

			_forms.splice(deleteFormIndex, 1);
			setForms([..._forms]);
		} else {
			alert('Something went wrong');
		}

		setLoading(false);
	};

	return (
		<NavigationContainer>
			<Group position="apart" mb={24}>
				<Title order={1} color="white">
					Campaigns
				</Title>
				<Button color="orange" variant="light">
					<Anchor color="orange" href="/formbuilder">
						New Campaign
					</Anchor>
				</Button>
			</Group>
			{loading ? (
				<Group position="center" mb={24}>
					<Loader color="orange" />
				</Group>
			) : null}
			<FormListing data={forms} onDelete={onDelete} loading={loading} />
		</NavigationContainer>
	);
};

export default Campaigns;
